import axios from 'axios';
const baseUrl = '/api';

const passwordResetLink = async (email: string) => {
  try {
    return await axios.post(`${baseUrl}/password/forgot-password`, {
      email: email,
    });
  } catch (error) {
    console.log(error);
    return {
      data: null,
    };
  }
};

const passwordReset = async (token: string, password: string) => {
  return await axios.post(`${baseUrl}/password/reset`, {
    token: token,
    password: password,
  });
};

const passwordChange = async (userId: number, oldPassword: string, newPassword: string) => {
  return await axios.put(`${baseUrl}/password/change/${userId}`, {
    oldPassword: oldPassword,
    newPassword: newPassword,
  });
};

export {
  passwordResetLink,
  passwordReset,
  passwordChange,
}
