import axios from "axios";
import {JWT_LOCAL_STORAGE_KEY} from "../constants";

const getJwt = () => localStorage.getItem(JWT_LOCAL_STORAGE_KEY);

const getAuthorizationIfExists = () => {
    const jwt = getJwt();
    if(!jwt) return undefined;
    else return {
        headers: {
            Authorization: `Bearer ${jwt}`
        }
    }
}

export const get = async (url: string) => {
    return await axios.get(url, getAuthorizationIfExists());
}

export const post = async <T>(url: string, body: T) => {
    return await axios.post(url, body, getAuthorizationIfExists());
}

export const del = async (url: string) => {
    return await axios.delete(url, getAuthorizationIfExists());
}

export const patch = async <T>(url: string, body: T) => {
    return await axios.patch(url, body, getAuthorizationIfExists());
}
