import React from 'react';
import { Form, Checkbox } from 'semantic-ui-react';

import {getBirthCountOptions, getDatesForSorting} from '../helpers';
import theme from '../../../theme';
import {ResMilking} from "../../../../../types/milking";

interface Props {
  milkings: ResMilking[];
  filterByBirthCount: number;
  setFilterByBirthCount: (value: number) => void;
  onlyTunnutettu: boolean;
  setOnlyTunnutettu: (value: boolean) => void;
  onlyFrozen: boolean;
  setOnlyFrozen: (value: boolean) => void;
  filterByDate: number;
  setFilterByDate: (value: number) => void;
}

const Filters: React.FC<Props> = ({
  milkings,
  filterByBirthCount,
  setFilterByBirthCount,
  onlyTunnutettu,
  setOnlyTunnutettu,
  onlyFrozen,
  setOnlyFrozen,
  filterByDate,
  setFilterByDate,
}) => {
  return (
    <div style={{ margin: '1rem 0' }}>
      <Form>

        <Form.Field>
          <label style={{ color: theme.colors.bgGreen }}>
            Suodata päivämäärän perusteella
          </label>
          <select
              value={filterByDate}
              onChange={(e) => setFilterByDate(Number(e.target.value))}
          >
            {getDatesForSorting().map((option) => (
                <option key={option.key} value={option.value}>
                  {option.name}
                </option>
            ))}
          </select>
        </Form.Field>

        <Form.Field>
          <label style={{ color: theme.colors.bgGreen }}>
            Suodata poikimiskertojen perusteella
          </label>
          <select
            value={filterByBirthCount}
            onChange={(e) => setFilterByBirthCount(Number(e.target.value))}
          >
            {getBirthCountOptions(milkings).map((option) => (
              <option key={option.key} value={option.value}>
                {option.name}
              </option>
            ))}
          </select>
        </Form.Field>
        <div className="field-row">
        <Form.Field>
          <label style={{ color: theme.colors.bgGreen }}>
            Näytä vain tunnutetut
          </label>
          <Checkbox
            checked={onlyTunnutettu}
            label='Kyllä'
            onClick={() => setOnlyTunnutettu(!onlyTunnutettu)}
          />
        </Form.Field>
          <Form.Field>
            <label style={{ color: theme.colors.bgGreen }}>
              Näytä vain pakastetut
            </label>
            <Checkbox
              checked={onlyFrozen}
              label='Kyllä'
              onClick={() => setOnlyFrozen(!onlyFrozen)}
            />
          </Form.Field>
        </div>
      </Form>
    </div>
  );
};

export default Filters;
