import React from 'react';
import {Menu, Image} from 'semantic-ui-react';

import ternitutkaLogo from '../../img/ternitutka_logo.png';

const MenuBar: React.FC = () => (
    <Menu pointing secondary>
        <Menu.Item>
            <Image alt='Ternitutka logo' src={ternitutkaLogo} size='medium'/>
        </Menu.Item>
    </Menu>
);

export default MenuBar;
