import React, {useEffect, useState} from 'react';
import {Button, Container, Form} from 'semantic-ui-react';
import theme from '../../theme';
import {passwordReset} from '../../services/passwordReset';

interface Props {
  notify: (message: string, type: 'success' | 'fail') => void;
}

export const ForgotPassword: React.FC<Props> = ({notify}) => {

  const parseActivationCodeFromUrl = () => window.location.pathname.split('/')[3];
  const activationToken = parseActivationCodeFromUrl();
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const openLoginPage = () => {
    window.history.replaceState(null, '', '/');
    dispatchEvent(new PopStateEvent('popstate'));
  }

  useEffect(() => {
    /* */
  }, [activationToken, notify]);

  const onSubmit = async () => {
    try {
      await passwordReset(activationToken, password);
      openLoginPage();
      notify('Salasanan resetointi onnistui. Kirjaudu sisään käyttämällä uutta salasanaa.', 'success');
    } catch {
      notify('Jotain meni vikaan salasanan resetoinnissa.', 'fail');
    }
  };

  const passwordHasErrors = () => password.length > 7 ? null : 'Salasanan täytyy olla vähintään 8 merkkiä pitkä';
  const password2HasErrors = () => password === password2 ? null : 'Salasanojen täytyy olla samat';
  const canSubmit = () => !passwordHasErrors() && !password2HasErrors();

  return (
    <div>
      <Container>
        <div>
          <h1 style={{ color: theme.colors.main }}>Salasanan nollaaminen</h1>
          <Form>
            <Form.Field>
              <label style={{ color: theme.colors.bgGreen }}>Salasana</label>
              <Form.Input
                value={password}
                type='password'
                onChange={(e) => setPassword(e.target.value)}
                placeholder='Salasana'
                error={passwordHasErrors()}
              />
            </Form.Field>
            <Form.Field>
              <label style={{ color: theme.colors.bgGreen }}>Salasana uudelleen</label>
              <Form.Input
                value={password2}
                type='password'
                onChange={(e) => setPassword2(e.target.value)}
                placeholder='Salasana uudelleen'
                error={password2HasErrors()}
              />
            </Form.Field>
            <Form.Field>
              <Button
                disabled={!canSubmit()}
                id='login-button'
                style={{ backgroundColor: theme.colors.bgGreen, color: 'white' }}
                onClick={onSubmit}
              >
                TALLENNA UUSI SALASANA
              </Button>
            </Form.Field>
          </Form>
        </div>
      </Container>
    </div>
  );
};
