import {ReqUserSignUp, ResUserSignUp} from "../../../types/signup";
import axios from "axios";

const baseUrl = '/api/signup';

export const getSignUpInfo = async (token: string): Promise<ResUserSignUp> => {
  const res = await axios.get(`${baseUrl}/${token}`);
  return res.data as ResUserSignUp;
};

export const postSignUp = async (body: ReqUserSignUp): Promise<void> => {
  const res = await axios.post(`${baseUrl}/`, body);
  return res.data;
};
