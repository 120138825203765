import React, {useState} from "react";
import {Button, Confirm, Dropdown, Form, Icon} from "semantic-ui-react";
import {ResCow} from "../../../../types/cow";
import theme from "../../theme";
import {cowFullName} from "../../utils";
import "../../index.css";

interface Props {
  cows: ResCow[];
  onDeleteCow: (value: number) => void;
  selectedCowId: number | undefined;
  onCowSelect: (cowId: number | undefined) => void;
}

export const CowList: React.FC<Props> = ({cows, onDeleteCow, selectedCowId, onCowSelect}) => {

  const [confirmDeleteCowId, setConfirmDeleteCowId] = useState<number | undefined>(undefined);
  const [cowSearch, setCowSearch] = useState('');
  const [open, _setOpen] = useState(false);

  const setOpen = (open: boolean) => {
    if (!open) setCowSearch('');
    _setOpen(open);
  }

  const findConfirmDeleteCow = () => cows.find(e => e.id === confirmDeleteCowId);

  const onSelect = (cowId: number | undefined) => {
    onCowSelect(cowId);
    setOpen(false);
  }

  return (
    <Form.Field>
      <label style={{ color: theme.colors.bgGreen }}>Valitse eläin</label>
      <Dropdown
        onBlur={() => setOpen(false)}
        onClick={() => setOpen(!open)}
        onOpen={() => setCowSearch('')}
        onSearchChange={(e, { searchQuery }) => setCowSearch(searchQuery)}
        searchQuery={cowSearch}
        open={open}
        search
        selection
        fluid
        text={cowFullName(cows.find(e => e.id === selectedCowId)) || 'Uusi eläin'}>
        <Dropdown.Menu className='cow-dropdown'>
          <Dropdown.Item text='Uusi eläin' onClick={() => onSelect(undefined)}/>
          <Dropdown.Divider />
          {
            cows.filter(cow => cow.earTag.toString().includes(cowSearch) || cow.name.toLowerCase().includes(cowSearch.toLowerCase())).map((cow) => (
              <Dropdown.Item key={cow.id} onClick={() => onSelect(cow.id)}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <p>{cowFullName(cow)}</p>
                  <Button icon size='mini' onClick={() => setConfirmDeleteCowId(cow.id)}>
                    <Icon name='trash'/>
                  </Button>
                </div>
              </Dropdown.Item>
            ))
          }
        </Dropdown.Menu>
      </Dropdown>
      <Confirm
        content={`Oletko varma että haluat poistaa eläimen ${findConfirmDeleteCow()?.name}?`}
        cancelButton='Peruuta'
        open={!!confirmDeleteCowId}
        onCancel={() => setConfirmDeleteCowId(undefined)}
        onConfirm={() => {
          setConfirmDeleteCowId(undefined)
          const cowToBeDeleted = findConfirmDeleteCow();
          cowToBeDeleted && onDeleteCow(cowToBeDeleted?.id);
        }}
      />
    </Form.Field>
  );
}
