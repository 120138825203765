import React, { useState, useEffect } from 'react';
import {Button, Form, Grid} from 'semantic-ui-react';

import Logger from './logger';
import Logs from './logs';

import { getAllCows } from '../services/cow';
import { getAllMilkings } from '../services/milking';

import { activeBtnStyle, inactiveBtnStyle } from '../theme';
import {ResCow} from '../../../types/cow';
import {ResMilking} from '../../../types/milking';
import {cowFullName} from '../utils';
import {JWT_LOCAL_STORAGE_KEY} from '../constants';

enum Tab {
  NewLog,
  ShowLogs
}

interface Props {
  setJwt: (user: string | null) => void;
  notify: (message: string, type: 'success' | 'fail') => void;
}

const Main: React.FC<Props> = ({ notify , setJwt}) => {
  const [cows, _setCows] = useState<ResCow[]>([]);
  const [milkings, _setMilkings] = useState<ResMilking[]>([]);
  const [activeTab, setActiveTab] = useState(Tab.NewLog);

  const setCows = (cows: ResCow[]) => {
    _setCows(cows.sort((a, b) => {
      if (cowFullName(a) > cowFullName(b)) return 1;
      else if (cowFullName(a) < cowFullName(b)) return -1;
      else return 0;
    }));
  }

  const setMilkings = (milkings: ResMilking[]) => {
    milkings.forEach((milking: ResMilking) => {
      milking.cowName = milking.cow.name;
    });
    _setMilkings(milkings.sort((a, b) => {
      if (a.date > b.date) return -1;
      else if (a.date < b.date) return 1;
      else return 0;
    }));
  }

  useEffect(() => {
    const initializeCows = async () => {
      const response = await getAllCows();
      if (response) {
        setCows(response);
      }
    };

    const initializeMilkings = async () => {
      const response = await getAllMilkings();
      if (response) {
        setMilkings(response);
      }
    };

    void initializeCows();
    void initializeMilkings();
  }, []);

  const logOutClick = async () => {
    window.localStorage.removeItem(JWT_LOCAL_STORAGE_KEY);
    notify('Uloskirjautuminen onnistui', 'success');
    setJwt(null);
  };

  const changePasswordClick = async () => {
    window.history.replaceState(null, '', '/password-change/');
    dispatchEvent(new PopStateEvent('popstate'));
  };

  const renderSwitch = () => {
    switch (activeTab) {
      case Tab.NewLog:
        return (<Logger notify={notify} setMilkings={setMilkings} milkings={milkings} cows={cows} setCows={setCows}/>)
      case Tab.ShowLogs:
        return (<Logs notify={notify} milkings={milkings} setMilkings={setMilkings} />);
    }
  }

  return (
    <div>
      <Button.Group widths='2'>
        <Button
          style={activeTab === Tab.NewLog ? activeBtnStyle : inactiveBtnStyle}
          onClick={() => setActiveTab(Tab.NewLog)}
        >
          Uusi kirjaus
        </Button>
        <Button
          style={activeTab === Tab.ShowLogs ? activeBtnStyle : inactiveBtnStyle}
          onClick={() => setActiveTab(Tab.ShowLogs)}
        >
          Näytä kirjaukset
        </Button>
      </Button.Group>
      {renderSwitch()}
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Form.Field
                style={{
                  marginTop: '2rem',
                  cursor: 'pointer',
                }}
            >
              <a onClick={() => logOutClick()}>Kirjaudu ulos</a>
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={8}>
            <Form.Field
                style={{
                  marginTop: '2rem',
                  cursor: 'pointer',
                  textAlign: 'right'
                }}
            >
              <a onClick={() => changePasswordClick()}>Vaihda salasana</a>
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default Main;
