import React from 'react';

import {BannerInterface} from '../../types';

interface Props {
    banner: BannerInterface | undefined;
}

const Footer: React.FC<Props> = ({banner}) => {
    if (banner !== undefined && banner.info_banner_enabled) {
        let bannerColor = '#ffffff';
        let textColor = '#000000'
        switch (banner.info_banner_style) {
            case "info":
                bannerColor = '#54B4D3';
                textColor = 'white';
                break;
            case "warning":
                bannerColor = '#E4A11B';
                textColor = 'white';
        }
        return <div
            style={{
                marginBottom: '1rem',
                marginTop: '1rem',
                textAlign: 'center',
                color: textColor,
                backgroundColor: bannerColor,
                paddingBottom: '15px',
                paddingTop: '10px',
                borderRadius: '5px',
                paddingRight: '10px',
                paddingLeft: '10px',
            }}
        >
            <h1 style={{
                paddingTop: 0,
                marginTop: 0,
                marginBottom: 0,
                paddingBottom: 0
            }}>
                {banner.info_banner_title}
            </h1>
            <h5 style={{
                marginTop: 0,
                paddingTop: 0,
            }}>
                {banner.info_banner_description}
            </h5>
        </div>
    } else {
        return null;
    }
};

export default Footer;
