import React, {useEffect, useState} from 'react';
import LoggerForm from './LoggerForm';
import { addMilking } from '../../services/milking';

import theme from '../../theme';
import {ReqCow, ResCow} from "../../../../types/cow";
import {ReqMilking, ResMilking} from "../../../../types/milking";
import {addCow, deleteCow} from "../../services/cow";

interface Props {
  notify: (message: string, type: 'success' | 'fail') => void;
  setMilkings: (milkings: ResMilking[]) => void;
  milkings: ResMilking[];
  cows: ResCow[];
  setCows: (value: ResCow[]) => void;
}

const defaults = {
  selectedCowId: undefined,
  newCow: { name: '', earTag: NaN},
  minutesSinceGivingBirth: 0,
  nthBirth: 1,
  litres: NaN,
  brix: NaN,
  tunnutus: false
}

const Logger: React.FC<Props> = ({
  notify,
  setMilkings,
  milkings,
  cows,
  setCows
}) => {
  const [selectedCowId, setSelectedCowId] = useState<number | undefined>(defaults.selectedCowId);
  const [newCow, setNewCow] = useState<ReqCow>(defaults.newCow);
  const [minutesSinceGivingBirth, setMinutesSinceGivingBirth] = useState(defaults.minutesSinceGivingBirth);
  const [nthBirth, setNthBirth] = useState(defaults.nthBirth);
  const [litres, setLitres] = useState(defaults.litres);
  const [brix, setBrix] = useState(defaults.brix);
  const [tunnutus, setTunnutus] = useState(defaults.tunnutus);
  const [date, setDate] = useState(new Date());

  const resetState = () => {
    setSelectedCowId(defaults.selectedCowId);
    setNewCow(defaults.newCow);
    setMinutesSinceGivingBirth(defaults.minutesSinceGivingBirth);
    setNthBirth(defaults.nthBirth);
    setLitres(defaults.litres);
    setBrix(defaults.brix);
    setTunnutus(defaults.tunnutus);
    setDate(new Date());
  }

  const onSubmit = async () => {

    let cowToBeMilked = selectedCowId;

    if (!cowToBeMilked) {
      const createdCow = await addCow(newCow);
      cowToBeMilked = createdCow.id;
      setCows([...cows, createdCow]);
    }

    const milkingToAdd: ReqMilking = {
      cowId: cowToBeMilked,
      minutesSinceGivingBirth,
      nthBirth,
      brix,
      litres,
      tunnutus,
      date: date.toISOString()
    };

    try {
      const milking = await addMilking(milkingToAdd);
      setMilkings([...milkings, milking]);
      resetState();
      notify(`Kirjaus lisätty eläimelle ${milking.cow.name}`, 'success');
    } catch (error) {
      console.error(error)
      notify('Mittauksen lisääminen ei onnistunut', 'fail');
    }
  };

  const onDeleteCow = async (cowId: number) => {
    try {
      const deletedCow = await deleteCow(cowId);
      const filteredCows = cows.filter(cow => cow.id !== cowId);
      setCows(filteredCows);
      setSelectedCowId(defaults.selectedCowId);
      notify(`Eläin ${deletedCow.earTag} ${deletedCow.name} poistettu`, 'success');
    } catch(error: any) {
      if (error?.response?.status === 422) {
        notify(`Eläimellä on poistamattomia kirjauksia. Poistahan kirjaukset ensin.`, 'fail');
      } else {
        console.error(error);
        notify(`Eläimen poisto ei onnistunut`, 'fail');
      }
    }
  };

  return (
    <React.Fragment>
      <h1 style={{ color: theme.colors.main }}>Ternimaidon laadun kirjaus</h1>
      <LoggerForm
        milkings={milkings}
        cows={cows}
        onDeleteCow={onDeleteCow}
        selectedCowId={selectedCowId}
        setSelectedCowId={setSelectedCowId}
        newCow={newCow}
        setNewCow={setNewCow}
        nthBirth={nthBirth}
        setNthBirth={setNthBirth}
        setMinutesSinceGivingBirth={setMinutesSinceGivingBirth}
        tunnutus={tunnutus}
        setTunnutus={setTunnutus}
        litres={litres}
        setLitres={setLitres}
        brix={brix}
        setBrix={setBrix}
        date={date}
        setDate={setDate}
        onSubmit={onSubmit}
      />
    </React.Fragment>
  );
};

export default Logger;
