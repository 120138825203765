import {ReqCow, ResCow} from "../../../types/cow";

const baseUrl = '/api/cows';

import {del, get, post} from "./axios";

export const getAllCows = async (): Promise<Array<ResCow>> => {
  const res = await get(`${baseUrl}/all`);
  return res.data as Array<ResCow>;
};

export const addCow = async (cow: ReqCow) => {
  const res = await post(`${baseUrl}/add-cow`, cow);
  return res.data as ResCow;
};

export const deleteCow = async (cowId: number) => {
  const res = await del((`${baseUrl}/delete-cow/${cowId}`));
  return res.data as ResCow;
}
