import React from 'react';

import theme from '../../theme';
import {Image} from "semantic-ui-react";
import lmLogo from '../../img/logo.png';

const Footer: React.FC = () => (
    <div
        style={{
            marginTop: '2rem',
            marginBottom: '2rem',
            textAlign: 'center',
            color: theme.colors.textGreen,
        }}
    >
        <br/>
        <Image alt='Lantmännen logo' src={lmLogo} size='small' centered/>
        <em>
            {' '}
            &copy; {new Date().getFullYear()}{' '}
            <a
                href='https://www.lantmannenagro.fi/'
                target='_blank'
                rel='noopener noreferrer'
            >
                {' '}
                Lantmännen Agro{' '}
            </a>{' '}
        </em>
    </div>
);

export default Footer;
