import React, {FC} from "react";
import theme from "../../theme";
import {Icon, Popup} from "semantic-ui-react";
import "../../index.css";

interface Props {
  header: string;
  tooltip: string;
}

export const FieldLabel: FC<Props> = ({header, tooltip}) => {
  return (
    <div style={{ marginBottom: '4px' }}>
      <label style={{ color: theme.colors.bgGreen }}><strong>{header}</strong></label>
      <span style={{marginLeft: '4px'}}>
        <Popup
          content={tooltip}
          trigger={<Icon className='green-icon' name='question circle outline'/>}
        />
      </span>
    </div>
  );
}
