import {ReqMilking, ResMilking} from "../../../types/milking";

const baseUrl = '/api/milkings';

import {del, get, patch, post} from "./axios";

export const getAllMilkings = async (): Promise<Array<ResMilking>> => {
  const res = await get(`${baseUrl}/all`);
  return res.data as Array<ResMilking>;
};

export const addMilking = async (milking: ReqMilking) => {
  const res = await post(`${baseUrl}/add-milking`, milking);
  return res.data as ResMilking;
};

export const deleteMilking = async (milkingId: number) => {
  const res = await del(`${baseUrl}/delete-milking/${milkingId}`);
  return res.data as ResMilking;
};

export const freezeMilking = async (milkingId: number) => {
  const res = await patch(`${baseUrl}/freeze-milking/${milkingId}`, undefined);
  return res.data as ResMilking;
};

export const unFreezeMilking = async (milkingId: number) => {
  const res = await patch(`${baseUrl}/unfreeze-milking/${milkingId}`, undefined);
  return res.data as ResMilking;
};
