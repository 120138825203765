import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts';
import { Label } from 'recharts';

import theme from '../../../theme';
import {ResMilking} from "../../../../../types/milking";

interface Props {
  chartData: ResMilking[];
  brixAverage: number;
}

const Charts: React.FC<Props> = ({ chartData, brixAverage }) => (
  <>
    <ResponsiveContainer width='100%' height={400}>
      <BarChart
        width={500}
        height={300}
        data={chartData}
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='cow.name' />
        <YAxis width={30} />

        <Tooltip />
        <Legend />
        <Bar dataKey='brix' fill={theme.colors.main} label name='Brix' />
        <ReferenceLine
          isFront={true}
          y={brixAverage}
          label={<Label stroke='black' strokeWidth={0.6} fontSize='1.5em' fill='red'>{`Keskiarvo ${brixAverage.toFixed(1)}%`}</Label>}
          stroke='red'
        />
      </BarChart>
    </ResponsiveContainer>
  </>
);

export default Charts;
