import React from 'react';
import { Message } from 'semantic-ui-react';

import { NotificationType } from '../../types';

interface Props {
  notification: NotificationType;
}

const Notification: React.FC<Props> = ({ notification }) => {
  if (notification.type === 'success' && notification.status) {
    return <Message floating success header={notification.message} />;
  }
  if (notification.type === 'fail' && notification.status || notification.type === 'warning' && notification.status) {
    return <Message floating negative header={notification.message} />;
  }
  return null;
};

export default Notification;
