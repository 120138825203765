import {ResCow} from "../../types/cow";

export const cowFullName = (cow: ResCow | undefined) => {
  if (!cow) return '';
  return cow.earTag + ' ' + cow.name;
}


export const filteredDecimalInput = (value: string): string => {
  const regex = /[^0-9,.]+/g;
  let clean = value.replace(regex, '')
    .replace(',', '.')
    .replaceAll(',,', '')
    .replaceAll('..', '');
  if ((clean.match(/\./g) || []).length > 1) {
    clean = clean.substring(0, clean.length - 1);
  }
  return clean;
}
