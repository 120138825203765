import React, { useState, useEffect } from 'react';
import { Container } from 'semantic-ui-react';

import Main from './components/Main';
import MenuBar from './components/layout/MenuBar';
import Footer from './components/layout/Footer';
import Login from './components/login';
import Banner from './components/layout/Banner';

import {JWT_LOCAL_STORAGE_KEY} from './constants';
import {getCurrentUser} from './services/user';
import {Index} from './components/signup';
import Notification from './components/layout/Notification';
import {ForgotPassword} from './components/password-reset/forgotPassword';
import {ChangePassword} from './components/password-reset/changePassword';
import {getBanner} from './services/banner';
import {ResBanner} from '../../types/banner';

const App: React.FC = () => {

  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [userId, setUserId] = useState<number>(0);
  const [user, setUser] = useState<string | null>(null);
  const [notification, setNotification] = useState({
    status: false,
    message: '',
    type: '',
  });
  const [banner, setBanner] = useState<ResBanner | undefined>(undefined);

  useEffect(() => {
    const onLocationChange  = () => {
      setCurrentPath(window.location.pathname);
    }

    addEventListener('popstate', onLocationChange);
    return () => { removeEventListener('popstate', onLocationChange) };
  }, []);

  useEffect(() => {
    const checkAuthentication = async () => {
      const jwt = window.localStorage.getItem(JWT_LOCAL_STORAGE_KEY);
      if (!jwt) {
        setUser(null);
        setBanner(undefined);
        return;
      }

      const user = await getCurrentUser();
      if (user) {
        setUser(jwt);
        setUserId(user.id);
      }
    }

    checkAuthentication();
  }, []);
  
  useEffect(() => {
    getBanner().then((banner) => {
      if (banner && user) {
        setBanner(banner);
      }
    }).catch(() => null);
  }, [user]);

  const notify = (message: string, type: 'success' | 'fail' | 'warning') => {
    setNotification({
      status: true,
      message: message,
      type: type,
    });
    const timeOutValue = type === 'warning' ? 10000 : 5000;
    setTimeout(() => {
      setNotification({ status: false, message: '', type: '' });
    }, timeOutValue);
  }

  const appSwitch = () => {
    if (currentPath.startsWith('/signup/')) return (<Index notify={notify}/>);
    if (currentPath.startsWith('/password/forgot-password/')) return (<ForgotPassword notify={notify}/>);
    if (currentPath.startsWith('/password-change/')) return (<ChangePassword notify={notify} setJwt={setUser} userId={userId}/>);
    if (!user) return <Login notify={notify} setJwt={setUser}/>
    else return <Main notify={notify} setJwt={setUser}/>
  }

  return (
    <div>
      <Container>
        <Banner banner={banner} />
        <MenuBar />
        <Notification notification={notification} />
        {appSwitch()}
        <Footer />
      </Container>
    </div>
  );
};

export default App;
