import React, {useState} from 'react';
import {Button, Checkbox, Form} from 'semantic-ui-react';

import theme from '../../theme';
import {ReqCow, ResCow} from '../../../../types/cow';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import '../../index.css';
import {ResMilking} from '../../../../types/milking';
import {CowList} from './CowList';
import {isNaN} from 'formik';
import {FieldLabel} from '../common/fieldLabel';
import {filteredDecimalInput} from '../../utils';

interface Props {
  milkings: ResMilking[];
  cows: ResCow[];
  onDeleteCow: (value: number) => void;
  selectedCowId: number | undefined;
  setSelectedCowId: (value: number | undefined) => void;
  newCow: ReqCow;
  setNewCow: (value: ReqCow) => void;
  nthBirth: number;
  setNthBirth: (value: number) => void;
  setMinutesSinceGivingBirth: (value: number) => void;
  tunnutus: boolean;
  setTunnutus: (value: boolean) => void;
  litres: number;
  setLitres: (value: number) => void;
  brix: number;
  setBrix: (value: number) => void;
  date: Date;
  setDate: (value: Date) => void;
  onSubmit: () => void;
}

const defaultStateHours = NaN;
const defaultStateMinutes = NaN;

const dateTooltipText = 'Mikäli kirjaat päivämäärän suoraan kenttään, kirjoita muodossa esim. 02042023.';
const aikaaPoikimisestaTooltipText = 'Poikimisesta lypsyyn, ei juottoon.';
const milkAmountTooltipText = 'Lypsetyn maidon määrä';

const LoggerForm: React.FC<Props> = ({
                                       milkings,
                                       cows,
                                       onDeleteCow,
                                       selectedCowId,
                                       setSelectedCowId,
                                       newCow,
                                       setNewCow,
                                       nthBirth,
                                       setNthBirth,
                                       setMinutesSinceGivingBirth,
                                       tunnutus,
                                       setTunnutus,
                                       litres,
                                       setLitres,
                                       brix,
                                       setBrix,
                                       date,
                                       setDate,
                                       onSubmit,
                                     }) => {

  const [hours, setHours] = useState<number>(defaultStateHours);  //initialState should be synced with ./index.tsx file's default
  const [minutes, setMinutes] = useState<number>(defaultStateMinutes);

  // Might cause problems later. Not good to have same state in more than one place
  const [nthBirthStr, setNthBirthStr] = useState<string>('');
  const [brixStr, setBrixStr] = useState<string>('');
  const [litresStr, setLitresStr] = useState<string>('');

  const findLatestNthBirthingByCow = (cowId: number) => {
    return 1 + milkings
      .filter(e => e.cow.id === cowId)
      .map(e => e.nthBirth)
      .reduce((acc, current) => acc > current ? acc : current, 0)
  }

  const onCowSelect = (cowId: number | undefined) => {

    if (!cowId) {
      setSelectedCowId(undefined);
      setNthBirthValue('1');
      return;
    }

    setSelectedCowId(cowId);
    setNthBirthValue(String(findLatestNthBirthingByCow(cowId)));
  };

  const setSinceGivingBirthHours = (value: number) => {
    setHours(value);
    const totalMinutes = (value || 0) * 60 + (minutes || 0);
    setMinutesSinceGivingBirth(totalMinutes);
  }

  const setSinceGivingBirthMinutes = (value: number) => {
    if (value > 59) value = 59;
    setMinutes(value);
    const totalMinutes = (hours || 0) * 60 + (value || 0);
    setMinutesSinceGivingBirth(totalMinutes);
  }

  const getEarTag = () => {
    return newCow.earTag ? Number(newCow.earTag).toString() : '';
  };

  const canSubmit = (): boolean =>
    (!!selectedCowId || (!!newCow.earTag && !!newCow.name)) &&
    !isNaN(brix) && !isNaN(litres);

  const preventNotNumbers = (event: any) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }};

  const setLitresValue = (event: any) => {
    const value = String(event.target.value);
    const clean = filteredDecimalInput(value);
    setLitresStr(clean);
    setLitres(Number(clean));
  }

  const setBrixValue = (event: any) => {
    const value = String(event.target.value);
    const clean = filteredDecimalInput(value);
    setBrixStr(clean);
    setBrix(Number(clean));
  }

  const setNthBirthValue = (value: string) => {
    const clean = filteredDecimalInput(value);
    setNthBirthStr(clean);
    setNthBirth(Number(clean));
  }

  return (
    <Form>
      <Form.Field>
        <CowList
          cows={cows}
          selectedCowId={selectedCowId}
          onCowSelect={onCowSelect}
          onDeleteCow={onDeleteCow}
        />
      </Form.Field>
      {
        !selectedCowId && (<>
          <div className='field-row' style={{marginBottom: '1rem'}}>
            <Form.Field>
              <label style={{ color: theme.colors.bgGreen }}>Korvanumero</label>
              <input
                type='number'
                value={getEarTag()}
                onChange={(e) => setNewCow({...newCow, earTag: Number(e.target.value)} as ReqCow)}
                placeholder='1234'
              />
            </Form.Field>
            <Form.Field>
              <label style={{ color: theme.colors.bgGreen }}>Nimi</label>
              <input
                value={newCow?.name}
                onChange={(e) => setNewCow({...newCow, name: e.target.value} as ReqCow)}
                placeholder='Lemmu'
              />
            </Form.Field>
          </div>
        </>)
      }
      <Form.Field>
        <label style={{ color: theme.colors.bgGreen }}>Poikimiskerta</label>
        <input
          value={nthBirthStr}
          type='text'
          onChange={(e) => setNthBirthValue(e.target.value)}
          placeholder='Poikimiskerta'
        />
      </Form.Field>
      <div className='field-row' style={{gap: 2}}>
        <Form.Field style={{flexGrow: 0}}>
          <label style={{ color: theme.colors.bgGreen }}>
            Aikaa poikimisesta (tuntia)
          </label>
          <input
            onKeyPress={preventNotNumbers}
            min={0}
            value={hours}
            type='number'
            onChange={(e) => {
              if (e.target.value === '') setSinceGivingBirthHours(NaN)
              else setSinceGivingBirthHours(Number(e.target.value))
            }}
            placeholder='tuntia'
          />
        </Form.Field>
        <Form.Field style={{flexGrow: 0}}>
            <FieldLabel header='(minuuttia)' tooltip={aikaaPoikimisestaTooltipText}/>
          <input
            onKeyPress={preventNotNumbers}
            min={0}
            value={minutes}
            type='number'
            onChange={(e) => {
              if (e.target.value === '') setSinceGivingBirthMinutes(NaN)
              else setSinceGivingBirthMinutes(Number(e.target.value))
            }}
            placeholder='minuuttia'
          />
        </Form.Field>
      </div>
      <Form.Field>
        <label style={{ color: theme.colors.bgGreen }}>Onko tunnutettu?</label>
        <Checkbox
          checked={tunnutus}
          label='Kyllä'
          onClick={() => setTunnutus(!tunnutus)}
        />
      </Form.Field>
      <div className='field-row' style={{marginBottom: '1rem'}}>
        <Form.Field>
            <FieldLabel header='Maidon määrä (litraa)' tooltip={milkAmountTooltipText}/>
          <input
            value={litresStr}
            type='text'
            step='0.1'
            onChange={(e) => {
              setLitresValue(e);
            }}
            placeholder='Maidon määrä (litraa)'
          />
        </Form.Field>
      </div>
      <div className='field-row' style={{marginBottom: '1rem'}}>
        <Form.Field>
          <label style={{ color: theme.colors.bgGreen }}>Brix %</label>
          <input
            value={brixStr}
            type='text'
            step='0.1'
            onChange={(e) => {
              setBrixValue(e);
            }}
            placeholder='Brix %'
          />
        </Form.Field>
      </div>
      <Form.Field>
        <FieldLabel header='Päivämäärä' tooltip={dateTooltipText}/>
        <SemanticDatepicker
          clearable={false}
          locale='fi-FI'
          firstDayOfWeek={1}
          format='DD.MM.YYYY'
          placeholder='pp.kk.vvvv'
          type='basic'
          onChange={(event, data) => {
            if (!data.value) return;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const date: Date = data.value;
            date.setHours(12, 0, 0, 0);
            setDate(date);
          }}
          value={date}
        />
      </Form.Field>
      <Button
        disabled={!canSubmit()}
        onClick={() => {
          onSubmit();
          setSelectedCowId(undefined);
          setHours(defaultStateHours);
          setMinutes(defaultStateMinutes);
        }}
        positive>
        TALLENNA TIEDOT
      </Button>
    </Form>
  );
}

export default LoggerForm;
