import {ResMilking} from "../../../../types/milking";


export const getBrixAverage = (milkings: ResMilking[]) => {
  const brixAverage =
    milkings.reduce((total, next) => total + next.brix, 0) / milkings.length;

  return brixAverage;
};

export const getBirthCountOptions = (milkings: ResMilking[]) => {
  let birthCountOptions = [
    ...Array.from(new Set(milkings.map((milking) => milking.nthBirth))),
  ]
    .sort()
    .map((option) => ({
      key: option,
      name: `${option}. poikimiskerta`,
      value: option,
    }))
    .filter((value, index, self) => self.indexOf(value) === index);

  birthCountOptions = [
    {
      key: 0,
      name: 'Kaikki poikimiskerrat',
      value: 0,
    },
    ...birthCountOptions,
  ];

  return birthCountOptions;
};

export const getDatesForSorting = () => {
  const dateOptions = [];
  dateOptions.push({key: 0, name: 'Kaikki ajankohdat', value: 0});
  dateOptions.push({key: 12, name: '12 kk', value: 365});
  dateOptions.push({key: 6, name: '6 kk', value: 180});
  dateOptions.push({key: 3, name: '3 kk', value: 90});
  dateOptions.push({key: 1, name: '1 kk', value: 30});
  return dateOptions;
}

export const dateIsOlderThan = (recordDate: string, days: number): boolean => {
  const diff = (new Date().getTime() - new Date(recordDate).getTime());
  const daysDifference = Math.floor(diff / (1000 * 60 * 60 * 24)) + 1;
  return daysDifference <= days;
}
