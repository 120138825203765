import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Confirm, SemanticWIDTHS, Table} from 'semantic-ui-react';
import {ResMilking} from '../../../../../types/milking';
import { Icon } from 'semantic-ui-react'
import {cowFullName} from "../../../utils";
import _ from 'lodash';

interface Props {
  milkings: ResMilking[];
  onDelete: (milking: ResMilking) => void;
  onMilkingFrozenChange: (milking: ResMilking, frozen: boolean) => void;
}


const List: React.FC<Props> = ({ milkings, onDelete, onMilkingFrozenChange }) => {
  const [confirmDeleteMilkingId, setConfirmDeleteMilkingId] = useState<number | undefined>(undefined);
  const [deleteFreezeDialog, setDeleteFreezeDialog] = useState<boolean>(false)
  const [milkingToEdit, setMilkingToEdit] = useState<any>(undefined)

  const tableSortingReducer = (state: any, action: any) => {
    switch (action.type) {
      case 'CHANGE_SORT':
        //if (state.column === action.column && state.column !== undefined) {
        if (state.column === action.column && state.column !== undefined) {
          return {
            ...state,
            data: state.data.slice().reverse(),
            direction: state.direction === 'ascending' ? 'descending' : 'ascending',
          }
        }

        return {
          column: action.column,
          data: _.sortBy(state.data, [action.column]),
          direction: 'ascending',
        }
      default:
        throw new Error()
    }
  }

  const [state, dispatch] = React.useReducer(tableSortingReducer, {
    column: null,
    data: milkings,
    direction: undefined,
  });

  useEffect(() => {
    state.data = milkings;
    //  dispatch({ type: 'CHANGE_SORT', column: state.column })
    dispatch({ type: 'CHANGE_SORT' })
  }, [milkings]);

  const findConfirmDeleteMilking = () => milkings.find(e => e.id === confirmDeleteMilkingId);

  const confirmDeleteMilkingLocalDate = () => {
    const confirmDeleteMilking = findConfirmDeleteMilking();
    if (!confirmDeleteMilking) return undefined;
    return new Date(confirmDeleteMilking.date).toLocaleDateString('fi-FI');
  }

  const headerCells: { label: string; width: SemanticWIDTHS | undefined; accessor: any; sortable: boolean; }[] = [
    { label: 'Eläin', width: 4, accessor: 'cowName', sortable: true },
    { label: 'Poikimiskerta', width: 3, accessor: 'nthBirth', sortable: true },
    { label: 'Maidon määrä', width: 3, accessor: 'litres', sortable: true },
    { label: 'Brix%', width: 3, accessor: 'brix', sortable: true },
    { label: 'Päivämäärä', width: 3, accessor: 'date', sortable: true },
    { label: 'Pakastettu', width: 2, accessor: 'frozen', sortable: false },
    { label: '', width: 1, accessor: 'action', sortable: false },
  ];

  const handlePrint = () => {
    window.print();
  };

  const handleRemoveFreezer = (data: any, milking: any) => {
    setMilkingToEdit(milking)
    data.checked ? onMilkingFrozenChange(milking, data.checked) : setDeleteFreezeDialog(true)
  }
  // https://react.semantic-ui.com/collections/table/#variations-sortable
  return (
      <div>
    <Table striped sortable>
      <Table.Header>
        <Table.Row>
          {headerCells.map(({ label, width, accessor, sortable }) => {
            return (
                <Table.HeaderCell
                    key={accessor}
                    width={width}
                    onClick={sortable ? () => dispatch({ type: 'CHANGE_SORT', column: accessor }) : null}
                    sorted={state.column === accessor ? state.direction : undefined}
                >
                  {label}
                </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {state.data.map((milking: ResMilking) => (
          <Table.Row key={milking.id}>
            <Table.Cell>{cowFullName(milking.cow)}</Table.Cell>
            <Table.Cell>{milking.nthBirth}</Table.Cell>
            <Table.Cell>{milking.litres} litraa</Table.Cell>
            <Table.Cell>{milking.brix}%</Table.Cell>
            <Table.Cell>{new Date(milking.date).toLocaleDateString('fi-FI')}</Table.Cell>
            <Table.Cell textAlign='center'>
              <Checkbox
                  checked={milking.frozen}
                  onClick={(event, data) =>
                      handleRemoveFreezer(data, milking)}/>
              <Confirm
                  content={`Oletko varma, että haluat ottaa merkinnän pois? 
                  Sen jälkeen listaus ei täytä Eläinten hyvinvointikorvauksen vapaavalintaisen toimenpiteen 
                  ”Vasikoiden olosuhteiden parantaminen” tukiehtoja.`}
                  cancelButton='Peruuta'
                  confirmButton='Kyllä, poista'
                  open={deleteFreezeDialog}
                  onCancel={() => setDeleteFreezeDialog(false)}
                  onConfirm={() => {
                    onMilkingFrozenChange(milkingToEdit, false)
                    setDeleteFreezeDialog(false)
                    setMilkingToEdit(undefined)
                  }}
              />
            </Table.Cell>
            <Table.Cell textAlign='right'>
              <Button icon onClick={() => setConfirmDeleteMilkingId(milking.id)}>
                <Icon name='trash' />
              </Button>
              <Confirm
                content={`Oletko varma että haluat poistaa kirjauksen päivältä ${confirmDeleteMilkingLocalDate()}?`}
                cancelButton='Peruuta'
                open={!!confirmDeleteMilkingId}
                onCancel={() => setConfirmDeleteMilkingId(undefined)}
                onConfirm={() => {
                  setConfirmDeleteMilkingId(undefined)
                  const milkingToBeDeleted = findConfirmDeleteMilking();
                  milkingToBeDeleted && onDelete(milkingToBeDeleted);
                }}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  <Button style={{marginBottom: '10px'}} onClick={handlePrint}>Tulosta</Button>
  </div>
  );
};

export default List;
