import React, {useEffect, useState} from "react";
import {Button, Container, Form} from "semantic-ui-react";
import {getSignUpInfo, postSignUp} from "../../services/signup";
import theme from "../../theme";
import {ReqUserSignUp} from "../../../../types/signup";

interface Props {
  notify: (message: string, type: 'success' | 'fail') => void;
}

export const Index: React.FC<Props> = ({notify}) => {

  const parseActivationCodeFromUrl = () => window.location.pathname.split('/')[2];
  const activationToken = parseActivationCodeFromUrl();
  const [customerId, setCustomerId] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [farmName, setFarmName] = useState('');

  const openLoginPage = () => {
    window.history.replaceState(null, '', '/');
    dispatchEvent(new PopStateEvent('popstate'));
  }

  useEffect(() => {
    const initCustomerId = async () => {
      try {
        setCustomerId((await getSignUpInfo(activationToken)).customerId);
      } catch (error) {
        openLoginPage();
        notify('Epäkelpo rekisteröintilinkki', 'fail');
      }
    };

    initCustomerId();
  }, [activationToken, notify]);

  const onSubmit = async () => {
    const signupBody = {
      token: activationToken,
      username: customerId,
      password: password,
      farmName: farmName
    } as ReqUserSignUp;
    try {
      await postSignUp(signupBody);
      openLoginPage();
      notify('Rekisteröinti onnistui. Kirjaudu sisään luomillasi tunnuksilla.', 'success');
    } catch {
      notify('Jotain meni vikaan rekisteröityessä.', 'fail');
    }
  };

  const passwordHasErrors = () => password.length > 7 ? null : 'Salasanan täytyy olla vähintään 8 merkkiä pitkä';
  const password2HasErrors = () => password === password2 ? null : 'Salasanojen täytyy olla samat';
  const farmNameHasErrors = () => farmName.trim() ? null : 'Nimi ei saa olla tyhjä';
  const canSubmit = () => !passwordHasErrors() && !password2HasErrors() && !farmNameHasErrors();

  return (
    <div>
      <Container>
        <div>
          <h1 style={{ color: theme.colors.main }}>Rekisteröi käyttäjätunnus Ternitutkaan</h1>
          <Form>
            <Form.Field>
              <label style={{ color: theme.colors.bgGreen }}>Käyttäjätunnus</label>
              <input
                value={customerId}
                type='text'
                disabled={true}
              />
            </Form.Field>
            <Form.Field>
              <label style={{ color: theme.colors.bgGreen }}>Salasana</label>
              <Form.Input
                value={password}
                type='password'
                onChange={(e) => setPassword(e.target.value)}
                placeholder='Salasana'
                error={passwordHasErrors()}
              />
            </Form.Field>
            <Form.Field>
              <label style={{ color: theme.colors.bgGreen }}>Salasana uudelleen</label>
              <Form.Input
                value={password2}
                type='password'
                onChange={(e) => setPassword2(e.target.value)}
                placeholder='Salasana uudelleen'
                error={password2HasErrors()}
              />
            </Form.Field>
            <Form.Field>
              <label style={{ color: theme.colors.bgGreen }}>Maatilan nimi</label>
              <Form.Input
                value={farmName}
                type='text'
                onChange={(e) => setFarmName(e.target.value)}
                placeholder='Maatilan nimi'
                error={farmNameHasErrors()}
              />
            </Form.Field>
            <Form.Field>
              <Button
                disabled={!canSubmit()}
                id='login-button'
                style={{ backgroundColor: theme.colors.bgGreen, color: 'white' }}
                onClick={onSubmit}
              >
                REKISTERÖI KÄYTTÄJÄTUNNUS
              </Button>
            </Form.Field>
          </Form>
        </div>
      </Container>
    </div>
  );
};
