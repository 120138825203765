import React, {useState} from 'react';
import {Button, Container, Form} from 'semantic-ui-react';
import theme from '../../theme';
import {passwordChange} from '../../services/passwordReset';
import {JWT_LOCAL_STORAGE_KEY} from '../../constants';
import {getCurrentUser} from "../../services/user";

interface Props {
  userId: number;
  setJwt: (user: string | null) => void;
  notify: (message: string, type: 'success' | 'fail') => void;
}

export const ChangePassword: React.FC<Props> = ({notify, setJwt, userId}) => {

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');

  const openLoginPage = () => {
    window.history.replaceState(null, '', '/');
    dispatchEvent(new PopStateEvent('popstate'));
  }

  const onSubmit = async () => {
    try {
      if(!userId) {
        const fetchedUser = await getCurrentUser()
        userId = fetchedUser.id
      }
      await passwordChange(userId, oldPassword, newPassword);
      window.localStorage.removeItem(JWT_LOCAL_STORAGE_KEY);
      notify('Salasanan vaihto onnistui. Kirjaudu sisään käyttämällä uutta salasanaa.', 'success');
      setJwt(null);
      openLoginPage();
    } catch {
      notify('Jotain meni vikaan salasanan vaihdossa. Kirjoititko nykyisen salasanan oikein?', 'fail');
    }
  };

  const passwordHasErrors = () => newPassword.length > 7 ? null : 'Salasanan täytyy olla vähintään 8 merkkiä pitkä';
  const password2HasErrors = () => newPassword === newPassword2 ? null : 'Salasanojen täytyy olla samat';
  const canSubmit = () => !passwordHasErrors() && !password2HasErrors();

  return (
    <div>
      <Container>
        <div>
          <h1 style={{ color: theme.colors.main }}>Salasanan vaihtaminen</h1>
          <Form>
            <Form.Field>
              <label style={{ color: theme.colors.bgGreen }}>Nykyinen salasanasi</label>
              <Form.Input
                  value={oldPassword}
                  type='password'
                  onChange={(e) => setOldPassword(e.target.value)}
                  placeholder='Nykyinen salasanasi'
              />
            </Form.Field>
            <Form.Field
                style={{
                  marginTop: '4rem',
                }}
            >
              <label style={{ color: theme.colors.bgGreen }}>Uusi salasana</label>
              <Form.Input
                value={newPassword}
                type='password'
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder='Uusi salasana'
                error={passwordHasErrors()}
              />
            </Form.Field>
            <Form.Field>
              <label style={{ color: theme.colors.bgGreen }}>Uusi salasana uudelleen</label>
              <Form.Input
                value={newPassword2}
                type='password'
                onChange={(e) => setNewPassword2(e.target.value)}
                placeholder='Uusi salasana uudelleen'
                error={password2HasErrors()}
              />
            </Form.Field>
            <Form.Field>
              <Button
                disabled={!canSubmit()}
                id='login-button'
                style={{ backgroundColor: theme.colors.bgGreen, color: 'white' }}
                onClick={onSubmit}
              >
                TALLENNA UUSI SALASANA
              </Button>
            </Form.Field>
          </Form>
        </div>
      </Container>
    </div>
  );
};
