import React, { useState } from 'react';
import {Form, Button} from 'semantic-ui-react';
import theme from '../../theme';
import login from '../../services/login';
import '../../index.css';

import {JWT_LOCAL_STORAGE_KEY} from '../../constants';
import {FieldLabel} from '../common/fieldLabel';
import {passwordResetLink} from '../../services/passwordReset';

interface Props {
  setJwt: (user: string) => void;
  notify: (message: string, type: 'success' | 'fail' | 'warning') => void;
}

const usernameTooltipText = 'Käyttäjätunnus on tilan asiakasnumero Lantmännen Agron järjestelmässä. ' +
  'Asiakasnumeron löytää tilausvahvistuksesta, kuormakirjasta tai laskulta. ' +
  'Sen voi kysyä myös asiakaspalvelusta p. 020 770 8870 (ma-pe klo 8–16), ' +
  'asiakaspalvelu.agro@lantmannen.com.';

const Login: React.FC<Props> = ({ setJwt, notify }) => {
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordResetView, setPasswordResetView] = useState(false);
  const [passwordResetEmail, setPasswordResetEmail] = useState('');

  const onSubmit = async () => {
    const response = await login({ name, password });

    if (response.data) {
      if (response.data.valid <= 30){
        const originalDate = new Date(response.data.validTo);
        const formattedDate = `${originalDate.getDate()}.${originalDate.getMonth() + 1}.${originalDate.getFullYear()}`;

        const notifyMessage =
            `
            Lisenssi päättyy ${response.data.valid} päivän kuluttua. Päivittämällä tilauksesi heti et menetä jäljellä olevaa 
            ${response.data.valid} päivää, vaan uusi lisenssi alkaa ${formattedDate} lähtien. Lisätietoja sähköpostissa.
            `

        notify( notifyMessage, 'warning')
      }
      console.log(response.data);
      window.localStorage.setItem(
        JWT_LOCAL_STORAGE_KEY,
        response.data.token
      );
      setJwt(response.data);
    } else {
      notify('Tarkista käyttäjätunnus & salasana', 'fail');
    }
  };

  const passwordResetClick = async () => {
    const response = await passwordResetLink(passwordResetEmail);
    if (response.data) {
      notify('Salasanan nollaus linkki on lähetetty antamaasi sähköpostiosoitteeseen', 'success');
    } else {
      notify('Salasanan nollaus linkin lähetys epäonnistui', 'fail');
    }
  };

  return (
    <div>
      <h1 style={{ color: theme.colors.main }}>Kirjaudu sisään</h1>
      <Form>
        <Form.Field>
          <FieldLabel
            header='Käyttäjätunnus'
            tooltip={usernameTooltipText}
          />
          <input
            id='login-username'
            value={name}
            type='text'
            onChange={(e) => setName(e.target.value)}
            placeholder='Käyttäjätunnus'
            autoComplete='username'
          />
        </Form.Field>
        <Form.Field>
          <label style={{ color: theme.colors.bgGreen }}>Salasana </label>
          <input
            id='login-password'
            value={password}
            type='password'
            onChange={(e) => setPassword(e.target.value)}
            placeholder='Salasana'
            autoComplete='current-password'
          />
        </Form.Field>
        <Form.Field>
          <Button
            id='login-button'
            style={{ backgroundColor: theme.colors.bgGreen, color: 'white' }}
            onClick={onSubmit}
          >
            KIRJAUDU
          </Button>
        </Form.Field>
        <Form.Field
            style={{
              marginTop: '2rem',
              cursor: 'pointer',
            }}
        >
          <a onClick={() => setPasswordResetView(true)}>Unohditko salasanan?</a>
        </Form.Field>
        {
          passwordResetView ?
              <Form.Field>
                <label style={{ color: theme.colors.bgGreen }}>Syötä sähköpostiosoitteesi: </label>
                <label style={{ color: theme.colors.textDark, fontSize: '12px' }}>Sähköpostiosoite tarvitaan salasanan vaihtolinkin lähettämiseen.</label>
                <input
                    id='forgot-password-email-input'
                    value={passwordResetEmail}
                    type='email'
                    onChange={(e) => setPasswordResetEmail(e.target.value)}
                    placeholder='Sähköpostiosoite'
                    autoComplete='email'
                />
                <Button
                    id='login-button'
                    style={{ backgroundColor: theme.colors.bgGreen, color: 'white', marginTop: '1rem' }}
                    onClick={passwordResetClick}
                >
                  LÄHETÄ
                </Button>
              </Form.Field>
              : null
        }
      </Form>
    </div>
  );
};

export default Login;
