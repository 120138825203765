import axios from 'axios';
import {ReqLogin} from "../../../types/login";
const baseUrl = '/api/login';

const login = async (credentials: ReqLogin) => {
  try {
    const res = await axios.post(baseUrl, credentials);
    return res;
  } catch (error) {
    console.log(error);
    return {
      data: null,
    };
  }
};

export default login;
