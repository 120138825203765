import React, { useState, useEffect } from 'react';
import { Button } from 'semantic-ui-react';
import Filters from './filters';
import Charts from './charts';
import List from './list';

import {dateIsOlderThan, getBrixAverage} from './helpers';
import theme, { activeBtnStyle, inactiveBtnStyle } from '../../theme';
import {ResMilking} from '../../../../types/milking';
import {deleteMilking, freezeMilking, unFreezeMilking} from '../../services/milking';

interface Props {
  milkings: ResMilking[];
  setMilkings: (milkings: ResMilking[]) => void;
  notify: (message: string, type: 'success' | 'fail') => void;
}

const Logs: React.FC<Props> = ({ milkings, setMilkings, notify }) => {
  const [showChart, setShowChart] = useState(true);
  const [chartData, setChartData] = useState(milkings);
  const [filterByBirthCount, setFilterByBirthCount] = useState(0);
  const [brixAverage, setBrixAverage] = useState(getBrixAverage(milkings));
  const [onlyTunnutettu, setOnlyTunnutettu] = useState(false);
  const [onlyFrozen, setOnlyFrozen] = useState(false);
  const [filterByDate, setFilterByDate] = useState(0);

  useEffect(() => {

    let filteredMilkings = [...milkings];

    if (filterByDate !== 0) filteredMilkings = filteredMilkings.filter((data) => dateIsOlderThan(data.date, filterByDate));
    if (filterByBirthCount !== 0) filteredMilkings = filteredMilkings.filter((data) => data.nthBirth === filterByBirthCount);
    if (onlyTunnutettu) filteredMilkings = filteredMilkings.filter((milking) => milking.tunnutus);
    if (onlyFrozen) filteredMilkings = filteredMilkings.filter((milking) => milking.frozen);

    setChartData(filteredMilkings);
    setBrixAverage(getBrixAverage(filteredMilkings));

  }, [filterByDate, filterByBirthCount, onlyTunnutettu, onlyFrozen, milkings]);

  const onDeleteMilking = async (milking: ResMilking) => {
    try {
      await deleteMilking(milking.id);
      const newMilkings = milkings.filter(e => e.id !== milking.id);
      setMilkings(newMilkings);

      notify(`Kirjaus eläimeltä ${milking.cow.name} poistettu`, 'success');
    } catch (error) {
      console.error(error);
      notify('Kirjauksen poistaminen ei onnistunut', 'fail');
    }
  }

  const onMilkingFrozenChange = async (milking: ResMilking, frozen: boolean) => {
    try {
      const changedMilking = frozen ? await freezeMilking(milking.id) : await unFreezeMilking(milking.id);
      setMilkings([...milkings.filter(e => e.id !== milking.id), changedMilking]);
    } catch (error) {
      console.error(error);
      notify('Muutos kirjauksen pakastamiseen ei onnistunut', 'fail');
    }
  }

  return (
    <>
      <h1 style={{ color: theme.colors.main }}>Laatukirjaukset</h1>
      <Button.Group size='tiny' widths='2'>
        <Button
          size='tiny'
          style={showChart ? activeBtnStyle : inactiveBtnStyle}
          onClick={() => setShowChart(true)}
        >
          Näytä taulukko
        </Button>
        <Button
          size='tiny'
          style={showChart ? inactiveBtnStyle : activeBtnStyle}
          onClick={() => setShowChart(false)}
        >
          Näytä lista
        </Button>
      </Button.Group>
      <Filters
        milkings={milkings}
        filterByBirthCount={filterByBirthCount}
        setFilterByBirthCount={setFilterByBirthCount}
        onlyTunnutettu={onlyTunnutettu}
        setOnlyTunnutettu={setOnlyTunnutettu}
        onlyFrozen={onlyFrozen}
        setOnlyFrozen={setOnlyFrozen}
        filterByDate={filterByDate}
        setFilterByDate={setFilterByDate}
      />
      {showChart ? (
        <Charts chartData={chartData} brixAverage={brixAverage} />
      ) : (
        <List milkings={chartData} onMilkingFrozenChange={onMilkingFrozenChange} onDelete={onDeleteMilking} />
      )}
    </>
  );
};

export default Logs;
